<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加供应商</div>
      </div>
      <el-form
        :model="form"
        size="small"
        label-position="left"
        :rules="rules"
        ref="getDialog"
      >
        <el-form-item
          label="供应商名称"
          :label-width="formLabelWidth"
          prop="dealer_name"
        >
          <el-input
            v-model="form.dealer_name"
            placeholder="供应商名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="供应商地址"
          :label-width="formLabelWidth"
          prop="dealer_site"
        >
          <el-input
            v-model="form.dealer_site"
            placeholder="供应商地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系人姓名"
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input v-model="form.name" placeholder="联系人姓名"></el-input>
        </el-form-item>
        <el-form-item
          label="联系方式"
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input v-model="form.phone" placeholder="联系方式"></el-input>
        </el-form-item>
        <el-form-item
          label="合同id"
          :label-width="formLabelWidth"
          prop="contract_id"
        >
          <el-select
            v-model="form.contract_id"
            style="width: 100%"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="供应商分类"
          :label-width="formLabelWidth"
          prop="dealer_class"
        >
          <el-cascader
            :options="options3"
            :props="optionProps"
            v-model="form.dealer_class"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>

        <el-form-item
          label="结算方式"
          :label-width="formLabelWidth"
          prop="clearing_id"
        >
          <el-select
            v-model="form.clearing_id"
            style="width: 100%"
            placeholder="请选择结算方式"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联任务" :label-width="formLabelWidth">
          <el-cascader
            placeholder="关联任务"
            :options="optionsTask"
            :props="optionPropsTask"
            v-model="form.task_id"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="税率%"
          :label-width="formLabelWidth"
          prop="tax_rate"
        >
          <el-slider v-model="form.tax_rate"></el-slider>
        </el-form-item>
        <el-form-item
          label="账期（天数）"
          :label-width="formLabelWidth"
          prop="payment"
        >
          <el-input-number
            v-model="form.payment"
            :min="0"
            label="账期（天数）"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd" v-show="openState"
          >添 加</el-button
        >
      </div>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
        <div class="menu-box">
            <h2>供应商列表</h2>
            <div class="box-bd">
                    <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="catalogue"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="addOpen()">添加供应商</el-button>
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="dealer_name"
          label="供应商名称"
        ></el-table-column>
        <el-table-column prop="name" label="联系人姓名"></el-table-column>
        <el-table-column prop="phone" label="联系方式"></el-table-column>
        <el-table-column prop="tax_rate" label="税率:%"></el-table-column>
        <el-table-column prop="payment" label="账期（天数）"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="pageChange"
        :pager-count="15"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
            </div>
    </div>
      
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getAdd,
  getOption,
  getOption2,
  getOption3,
} from "@/api/psi/dealer.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      optionPropsTask: {
        value: "id",
        label: "name",
        children: "son",
        disabled: "state",
      },
      optionsTask: [],

      rules: {
        dealer_name: [
          { required: true, message: "请输入供应商名称", trigger: "blur" },
        ],
        dealer_site: [
          { required: true, message: "请输入供应商地址", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        contract_id: [
          { required: true, message: "请输入合同id", trigger: "change" },
        ],
        dealer_class: [
          { required: true, message: "请输入供应商分类", trigger: "change" },
        ],
        clearing_id: [
          { required: true, message: "请输入结算方式", trigger: "change" },
        ],
        tax_rate: [{ required: true, message: "请输入税率", trigger: "blur" }],
        payment: [{ required: true, message: "账期（天数）", trigger: "blur" }],
      },
      optionProps: {
        value: "id",
        label: "name",
        children: "sub",
      },
      openState: true,
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //上级列表
      options: [],
      options2: [],
      options3: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        dealer_name: "",
        dealer_site: "",
        name: "",
        phone: "",
        contract_id: "",
        dealer_class: [],
        clearing_id: "",
        tax_rate: 0,
        payment: "",
        task_id: [],
      },
      fileList: [],
      fileList2: [],
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true;
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.page_num = value;
      this.catalogue();
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      this.catalogue();
    },
    //打开添加
    addOpen() {
      this.openState = true;
      // this.dialogFormVisible = true;
      this.$router.push("/adddealer");
    },
    //打开编辑
    editOpen(row) {
      this.$router.push({
        path: "/editdealer",
        query: {
          id: row.id,
          name: "编辑供应商信息",
        },
      });
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      this.$refs.getDialog.validate(async (valid) => {
        if (!valid) {
          return;
        }
        let info = await getAdd(this.form);
        this.judge(info);
      });
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dialogVisible = false;
        this.catalogue();
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        dealer_name: "",
        dealer_site: "",
        name: "",
        phone: "",
        contract_id: "",
        dealer_class: [],
        clearing_id: "",
        tax_rate: 0,
        payment: "",
        task_id: [],
      };
      this.$refs["getDialog"].clearValidate();
      console.log("close");
    },
    //目录接口
    async catalogue() {
      let optionsTask = await this.$getOptionTask();
      this.optionsTask = optionsTask.data.data;
      let info = await getList(this.getInfo);
      let options = await getOption();
      let options2 = await getOption2();
      let options3 = await getOption3();
      this.options = options.data.data;
      this.options2 = options2.data.data;
      this.options3 = options3.data.data;
      this.tableData = info.data.data;
      this.exp.count = info.data.exp.count;
      this.exp.num = info.data.exp.num;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>